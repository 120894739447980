<template>
  <!--
    The view for the AcknowledgeProblemAll-component.
    Used to acknowledge all problems of a certain installation.
  -->
  <AcknowledgeProblemAll
    :source="source"
    :installation-id="installationId"
  />
</template>

<script>
export default {
  name: "ViewAcknowledgeProblemAll",
  components: {
    AcknowledgeProblemAll: () => import('@/components/Problem/AcknowledgeProblemAll.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: false,
      default () {
        return 'installation'
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('acknowledgeAllProblems')
    }
  }
}
</script>
